<template>
  <v-card-title class="px-0 pt-0">
    <h3 class="grey--text text--darken-1">{{ title }}</h3>
  </v-card-title>
</template>

<script>
export default {
  props: ["title"],
};
</script>
